<template>
  <div>
    <div class="Polaris-Card">
      <div class="Polaris-Card__Section" v-if="trackingInformation.status">
        <div>
          <div class="my-5 "
            :class=" (trackingInformation.status.toLowerCase() == 'delivered') ? 'text-success' : 'text-info' ">
            <div class="text-center">
              <h6 class="text-uppercase"> <span v-text=" 'status: ' "> </span> <b
                  v-text=" trackingInformation.status == 'Created' ? 'ready to ship' : trackingInformation.status "></b>
              </h6>
              <p class=" m-0 text-muted "> <span v-text=" 'Tracking number: ' "></span> <strong
                  v-text=" trackingInformation.tracking_number "></strong> </p>
            </div>
          </div>
        </div>
      </div>

      <div class="Polaris-Card__Section">
        <div class="Polaris-Card">
          <div class="Polaris-Card__Header">
            <div class="row">
              <div class="col">
                <h3 class="Polaris-Heading" v-text="'Shipping requirements'"> </h3>
              </div>
              <div class="col-auto">
                <div class="Polaris-ButtonGroup">
                  <div class="Polaris-ButtonGroup__Item">
                    <button type="button" class="Polaris-Button Polaris-Button--sizeSlim"
                      @click="fnLink(shippingInformation.label)">
                      <span class="Polaris-Button__Content"><span class="Polaris-Button__Text">Shipping label</span>
                      </span>
                    </button>
                  </div>
                  <div class="Polaris-ButtonGroup__Item" v-if="shippingInformation.bill_of_landing">
                    <button type="button" class="Polaris-Button Polaris-Button--sizeSlim" @click="setModalBOL(true)">
                      <span class="Polaris-Button__Content"><span class="Polaris-Button__Text">BOL</span>
                      </span>
                    </button>
                  </div>

                  <div class="Polaris-ButtonGroup__Item" v-if="shippingInformation.bill_of_landing">
                    <button href="button" class="Polaris-Button Polaris-Button--sizeSlim"
                      @click="fnLink(shippingInformation.label)">
                      <span class="Polaris-Button__Content"><span class="Polaris-Button__Text">Order summary</span>
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="Polaris-Card__Section">
            <div>

            </div>
            <p> <strong>Shipping label:</strong> Identification label. </p>
            <p v-if="shippingInformation.bill_of_landing">
              <strong>BOL:</strong>
              <span> A commercial invoice required for all international shipments.</span>
            </p>
            <p v-if="shippingInformation.bill_of_landing">
              <strong>Order summary:</strong>
              <span> Detailed order information required for all international shipments. </span>
            </p>
          </div>
        </div>
      </div>


      <div class="Polaris-Card__Section" v-if=" trackingInformation.status != 'Created' ">
        <div>
          <!-- Mobile -->
          <table class="table table-sm d-lg-none ">
            <thead>
              <tr>
                <th v-text="$t('tables.trackingHistory')"> </th>
              </tr>
            </thead>
            <tbody>
              <tr :class=" (index == 0 ) ? 'bg-success ' : '' "
                v-for=" (eventHis, index) in  trackingInformation.event_history" :key="index + 'mobile'">
                <td>
                  <div class="row">
                    <div class="col-12">
                      <p :class=" (index == 0 ) ? 'text-white ' : '' ">
                        <span v-text=" $t('tables.description') + ': ' "></span> <strong v-text="eventHis.description">
                        </strong>
                      </p>
                    </div>
                    <div class="col-12 col-sm-6">
                      <p class="text-truncate" :class=" (index == 0 ) ? 'text-white ' : '' ">
                        <span v-text=" $t('tables.location') + ': ' "></span> <strong v-text="eventHis.location">
                        </strong>
                      </p>
                    </div>
                    <div class="col-12 col-sm-6">
                      <p :class=" (index == 0 ) ? 'text-white ' : '' ">
                        <span v-text=" $t('tables.date') + ': ' "></span> <strong
                          v-text="fnDate( eventHis.date, CONST.DATE_FORMATS.DD_MM_YYYY + ' - ' + CONST.DATE_FORMATS.HH_MM_SS )  ">
                        </strong>
                      </p>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>

          </table>
          <!-- Desktop -->
          <table class="table table-sm d-none d-lg-table ">
            <thead>
              <tr>
                <th v-text="$t('tables.description')"></th>
                <th v-text="$t('tables.location')"></th>
                <th v-text="$t('tables.date')"> </th>
              </tr>
            </thead>
            <tbody>
              <tr :class=" (index == 0 ) ? 'bg-success ' : '' "
                v-for=" (eventHis, index) in  trackingInformation.event_history" :key="index + 'desktop'">
                <td>
                  <p :class=" (index == 0 ) ? 'text-white ' : '' " v-text="eventHis.description">
                  </p>
                </td>
                <td>
                  <p :class=" (index == 0 ) ? 'text-white ' : '' " v-text="eventHis.location">
                  </p>
                </td>
                <td>
                  <p :class=" (index == 0 ) ? 'text-white ' : '' "
                    v-text="fnDate( eventHis.date, CONST.DATE_FORMATS.DD_MM_YYYY + ' - ' + CONST.DATE_FORMATS.HH_MM_SS )">
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <PolarisModal title="Bill of landing" :visible="modalUpdateBOL" @close-modal="fnCloneItems()" btnOkMessage="Update"
      @action-modal="fnValidateData()" :loading="loading.updateBOL">
      <p> I can print directly <b><a :href="shippingInformation.bill_of_landing" target="_blank"> here </a></b> or you can update it before printing. </p>
      <br>
      <v-observer class=" " ref="BOLForm" tag="form" @submit.prevent="fnValidateData">
        <div class="row mb-1 " v-for=" (item,index) in  orderInformation.items " :key=" index ">
          <div class="col-auto">
            <img v-image=" item.image_url " alt="" class="img-cover-40 border">
          </div>
          <div class="col">
            <div class="Polaris-FormLayout">
              <v-validation rules="required" v-slot="{ errors }" :name=" 'Item ' + (index + 1 ) + ' description'  ">
                <PolarisInputText v-model="item.name" :invalid=" errors[0] " />
              </v-validation>
            </div>
          </div>
        </div>
      </v-observer>
    </PolarisModal>
  </div>
</template>

<script>
  import PolarisModal from '@/components/shopify/polaris-modal';
  import PolarisInputText from '@/components/shopify/polaris-input-text';

  import {
    mapState,
    mapMutations,
    mapActions,
    mapGetters,
  } from "vuex";

  export default {
    data() {
      return {
        tempItems: [],
      }
    },
    components: {
      PolarisModal,
      PolarisInputText,
    },
    computed: {
      ...mapState('ShopifyOrder', ['trackingInformation', 'orderInformation', 'shippingInformation', 'modalUpdateBOL',
        'loading'
      ]),
    },
    methods: {
      ...mapMutations('ShopifyOrder', ['setModalBOL']),
      ...mapActions('ShopifyOrder', ['fnUpdateOrderBOL']),
      fnLink(link) {
        window.open(link);
      },
      async fnValidateData() {
        if (await this.$refs['BOLForm'].validate()) {
          this.fnUpdateOrderBOL(this.orderInformation.items);
        }
      },
      fnCloneItems() {
        this.setModalBOL(false);
        this.tempItems = this.$lodash.cloneDeep(this.orderInformation.items);
      },
    },
    mounted() {
      this.fnCloneItems();
    }
  }
</script>

<style>

</style>