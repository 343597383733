<template>
  <div v-if="visible">
    <div class="Polaris-Modal-Dialog__Container animated fadeIn" data-polaris-layer="true" data-polaris-overlay="true">
      <div>
        <div role="dialog" aria-labelledby="Polarismodal-header14" tabindex="-1" class="Polaris-Modal-Dialog">
          <div class="Polaris-Modal-Dialog__Modal">
            <div class="Polaris-Modal-Header">
              <div id="Polarismodal-header14" class="Polaris-Modal-Header__Title">
                <h2 class="Polaris-DisplayText Polaris-DisplayText--sizeSmall" v-text="title"> </h2>
              </div>
              <button class="Polaris-Modal-CloseButton" aria-label="Close" @click="$emit('close-modal')">
                <span class="Polaris-Icon Polaris-Icon--colorInkLighter Polaris-Icon--isColored">
                  <svg viewBox="0 0 20 20" class="Polaris-Icon__Svg" focusable="false" aria-hidden="true">
                    <path
                      d="M11.414 10l6.293-6.293a.999.999 0 1 0-1.414-1.414L10 8.586 3.707 2.293a.999.999 0 1 0-1.414 1.414L8.586 10l-6.293 6.293a.999.999 0 1 0 1.414 1.414L10 11.414l6.293 6.293a.997.997 0 0 0 1.414 0 .999.999 0 0 0 0-1.414L11.414 10z"
                      fill-rule="evenodd"></path>
                  </svg>
                </span>
              </button>
            </div>
            <div class="Polaris-Modal__BodyWrapper">
              <div class="Polaris-Modal__Body Polaris-Scrollable Polaris-Scrollable--vertical"
                data-polaris-scrollable="true">
                <section class="Polaris-Modal-Section">
                  <slot></slot>
                </section>
              </div>
            </div>
            <div class="Polaris-Modal-Footer">
              <div class="Polaris-Modal-Footer__FooterContent">
                <div class="Polaris-Stack Polaris-Stack--alignmentCenter">
                  <div class="Polaris-Stack__Item Polaris-Stack__Item--fill"></div>
                  <div class="Polaris-Stack__Item">
                    <div class="Polaris-ButtonGroup">
                      <div class="Polaris-ButtonGroup__Item">
                        <button type="button" class="Polaris-Button" @click="$emit('close-modal')">
                          <span class="Polaris-Button__Content">
                            <span class="Polaris-Button__Text" v-text="btnCancelMessage">
                            </span>
                          </span>
                        </button>
                      </div>
                      <div class="Polaris-ButtonGroup__Item" v-if="!hideBtnOk">
                        <button type="button" class="Polaris-Button Polaris-Button--primary "
                          :class=" { 'Polaris-Button--destructive': negative, 'Polaris-Button--disabled Polaris-Button--loading':loading } "
                          :disabled="loading" @click=" $emit('action-modal') ">
                          <span class="Polaris-Button__Content">
                            <span class="Polaris-Button__Spinner" v-show="loading">
                              <span
                                class="Polaris-Spinner Polaris-Spinner--colorInkLightest Polaris-Spinner--sizeSmall"><svg
                                  viewBox="0 0 20 20">
                                  <path
                                    d="M7.229 1.173a9.25 9.25 0 1011.655 11.412 1.25 1.25 0 10-2.4-.698 6.75 6.75 0 11-8.506-8.329 1.25 1.25 0 10-.75-2.385z">
                                  </path>
                                </svg>
                              </span>
                              <span role="status">
                                <span class="Polaris-VisuallyHidden" v-text="btnOkMessage"></span>
                              </span>
                            </span>
                            <span class="Polaris-Button__Text" v-text="btnOkMessage">
                            </span>
                          </span>
                        </button>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="Polaris-Backdrop"></div>
  </div>
</template>

<script>
  export default {
    props: {
      btnCancelMessage: {
        type: String,
        default: 'Close',
      },
      btnOkMessage: {
        type: String,
        default: 'Submit',
      },
      visible: {
        type: Boolean,
        default: false,
      },
      loading: {
        type: Boolean,
        default: false,
      },
      hideBtnOk: {
        type: Boolean,
        default: false,
      },
      negative: {
        type: Boolean,
        default: false,
      },
      title: {
        type: String,
        default: '',
      },
    }
  }
</script>

<style>

</style>